import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Components/Cookie";
import Toast from "../../Components/Toast";
import Navigation from "../../config/Navigation.json";
import APIEndpoint from "../../config/APIEndpoints.json";
import InputContext from "../../Components/InputContext";
import imgHost from "../../Components/ReUsed";
import placeHolder from "../../Assets/image-placeholder.png";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const [dashboardDetails, setDashboardDetails] = useState([]);

  const GetDashboardDetails = async () => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getDashboardDetails}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setDashboardDetails(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      showToast("info", "Sessions expired!");
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      navigate(Navigation.login);
    } else if (res.status === 401 && res.message !== "") {
      showToast("error", res.message);
    }
  };

  useEffect(() => {
    const Token = getDataFromCookies("7b9KpQ3rW6");
    const isLogin = getDataFromCookies("loggedIn");

    GetDashboardDetails();
    if ((Token === "" || Token == null) && (isLogin === "" || isLogin == null)) {
      navigate(Navigation.login);
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active">Dashboard</li>
          </div>
        </div>
        <div className="page-container">
          <div className="dashboard-container">
            <div className="dashboard-analytics">
              <h3 className="dashboard-box-heading">Business Analytics</h3>
              <div className="analytics-counter">
                <div className="analytics-counter-item">
                  <p>Total Sale</p>
                  {dashboardDetails.total_sales && (
                    <p>₹{dashboardDetails.total_sales}</p>
                  )}
                </div>
                <div className="analytics-counter-item">
                  <p>Total Inhouse Sale</p>
                  {dashboardDetails.total_inhouse_income && (
                    <p>₹{dashboardDetails.total_inhouse_income}</p>
                  )}
                </div>

                <div className="analytics-counter-item">
                  <p>Total Products</p>
                  <p>{dashboardDetails.total_product}</p>
                </div>
                <div className="analytics-counter-item">
                  <p>Product Published</p>
                  <p>{dashboardDetails.product_listed}</p>
                </div>
                <div className="analytics-counter-item">
                  <p>Total Seller</p>
                  <p>{dashboardDetails.total_seller}</p>
                </div>
                <div className="analytics-counter-item">
                  <p>Active Seller</p>
                  <p>{dashboardDetails.active_seller}</p>
                </div>
                <div className="analytics-counter-item">
                  <p>Total Customers</p>
                  <p>{dashboardDetails.total_customer}</p>
                </div>
                <div className="analytics-counter-item">
                  <p>Active Customers</p>
                  <p>{dashboardDetails.active_customer}</p>
                </div>
              </div>
            </div>
            <div className="analytics-multi-box">
              <div className="dashboard-analytics">
                <h3 className="dashboard-box-heading">Top Products</h3>
                <div className="InnerTableContainer">
                  <table className="order-table">
                    <thead>
                      <th>#</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Total</th>
                    </thead>
                    <tbody>
                      {dashboardDetails.top_products &&
                      dashboardDetails.top_products.length > 0 ? (
                        <>
                          {dashboardDetails.top_products.map((item, key) => (
                            <tr key={item.id}>
                              <td>{key + 1}</td>
                              <td>
                                <img
                                  src={`${imgHost}/${item.stocks[0].thumb_image}`}
                                  loading="lazy"
                                  onError={(e) => {
                                    e.target.src = placeHolder;
                                  }}
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.added_to_cart_wishlist}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>
                              No Data
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dashboard-analytics">
                <h3 className="dashboard-box-heading">Top Sellers</h3>
                <div className="InnerTableContainer">
                  <table className="order-table">
                    <thead>
                      <th>#</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Orders</th>
                    </thead>
                    <tbody>
                      {dashboardDetails.top_sellers &&
                      dashboardDetails.top_sellers.length > 0 ? (
                        <>
                          {dashboardDetails.top_sellers.map((item, key) => (
                            <tr key={item.id}>
                              <td>{key + 1}</td>
                              <td>
                                {item.f_name} {item.l_name}
                              </td>
                              <td style={{ wordBreak: "break-word" }}>
                                {item.email}
                                <br />
                                {item.phone}
                              </td>
                              <td>{item.total_orders}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>
                              No Data
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dashboard-analytics">
                <h3 className="dashboard-box-heading">Top Customers</h3>
                <div className="InnerTableContainer">
                  <table className="order-table">
                    <thead>
                      <th>#</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Cart & Wishlist</th>
                    </thead>
                    <tbody>
                      {dashboardDetails.top_customers &&
                      dashboardDetails.top_customers.length > 0 ? (
                        <>
                          {dashboardDetails.top_customers.map((item, key) => (
                            <tr key={item.id}>
                              <td>{key + 1}</td>
                              <td>
                                {item.f_name} {item.l_name}
                              </td>
                              <td style={{ wordBreak: "break-word" }}>
                                {item.email}
                                <br />
                                {item.phone}
                              </td>
                              <td>{item.total_added ? item.total_added : 0}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>
                              No Data
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
